import img1 from "../assets/img/image1.jpg";
import img2 from "../assets/img/image2.jpg";
import img3 from "../assets/img/image3.jpg";
import img4 from "../assets/img/image4.jpg";


export const data = [
    {
        imgUrl: img1
    },
    {
        imgUrl: img2
    },
    {
        imgUrl: img3
    },  
    {
        imgUrl: img4
    },
]